<template>
  <FoldableCard :title="t['79']">
    <template #filterbody>
      <div class='p-fluid formgrid grid hr-line-dashed-bottom'>
          <UserIdFilter @filterOperator='getUIDFilterOperator' :label-text="t['430']" :placeholder-text="t['430']" @filterValue='getUIDFilterValue' ref='userIdFilter'></UserIdFilter>
          <UserNameFilter @filterOperator='getNameFilterOperator' :label-text="t['431']" :placeholder-text="t['431']" @filterValue='getNameFilterValue' ref='userNameFilter'></UserNameFilter>
          <UserEmailFilter @filterOperator='getEmailFilterOperator' :label-text="t['432']" :placeholder-text="t['432']" @filterValue='getEmailFilterValue' ref='emailFilterValue'></UserEmailFilter>
          <UserStatusFilter @filterOperator='getStatusFilterOperator' :label-text="t['433']" :placeholder-text="t['433']" @filterValue='getStatusFilterValue' ref='userStatusFilter'></UserStatusFilter>
          <RoleFilter @filterOperator='getRoleFilterOperator' :label-text="t['434']" :placeholder-text="t['434']" @filterValue='getRoleFilterValue' ref='roleFilter'></RoleFilter>
      </div>
    </template>
    <template #filterfooter>
      <div class='mt-2'>
        <Button class="p-button-outlined mr-2" @click='clearUsersFilter'>
          <span class="p-button-label">{{ t['494'] }}</span>
        </Button>
        <Button class="p-button" @click='getAllUsers'>
          <span class="p-button-label">{{ t['78'] + ' ' + t['79'] }}</span>
        </Button>
      </div>
    </template>
  </FoldableCard>
  <div class='card'>
    <!-- USER LIST HEADER -->
    <h4 class='font-bold hr-line-dashed-bottom'>{{ t['436'] }}</h4>
    <!--   SEARCH AND ADD USERS   -->
    <div class='text-right mr-2 mb-3'>
      <span class='p-input-icon-left'>
        <i class='pi pi-search'/>
        <InputText v-model="filters['global'].value" :placeholder="t['82']"/>
      </span>
      <SplitButton :label="t['437']" icon='pi pi-plus' :model='actionsMenu' class='ml-2' @click="$router.push('/adduser')"></SplitButton>
    </div>
    <!-- USERS DATATABLE -->
    <DataTable :value='allUsers' class='p-datatable p-datatable-sm' :loading='loading'
               v-model:filters='filters'
               csvSeparator=';' :rowHover='true' :paginator='true' :rows='10' :rowsPerPageOptions='[10,20,50]' exportFilename='Users' ref='users'>
      <template #empty>
        <!--  TODO: align center    -->
        <span>{{ t['83'] }}</span>
      </template>
      <template #loading style='text-align: left'>
        <!--   TODO: align center   -->
        <span>{{ t['84'] }}</span>
      </template>
      <Column header='Name' field='name' :sortable='true'></Column>
      <Column header='Email' field='email' :sortable='true'></Column>
      <Column header='Default Role' field='defRoleText'></Column>
      <Column header='Status' field='status'>
        <template #body="slotProps">
          <span :class="'status-badge '+slotProps.data.status">{{slotProps.data.status}}</span>
        </template>
      </Column>
      <Column header='Expiration Date' field='expDate'></Column>
      <Column headerStyle='width: 8rem;' bodyStyle='overflow: visible'>
        <template #body="slotProps">
          <Button type="button" icon="pi pi-pencil" class="p-button p-button-outlined p-button-icon-only p-button-primary mr-2" @click="$router.push('/edituser/'+slotProps.data.id)"></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { usePrimeVue } from 'primevue/config';
import axios from 'axios';
import {FilterMatchMode} from 'primevue/api';

export default {
  name: 'Users',
  setup() {
    const store = useStore()
    const primeVue = usePrimeVue()

    return {
      token: computed(() => store.state.bearerToken),
      t: primeVue.config.locale,
    }
  },
  data() {
      return {
          allUsers: null,
          loading: true,
          filters: null,
          actionsMenu: [],
          UIDFilterOperator: '=',
          UIDFilterValue: '',
          NameFilterOperator: '=',
          NameFilterValue: '',
          EmailFilterOperator: '=',
          EmailFilterValue: '',
          StatusFilterOperator: 'NOT IN',
          StatusFilterValue: [],
          RoleFilterOperator: 'NOT IN',
          RoleFilterValue: [],
      }
  },
  created() {
      //Initilize filter
      this.initFilter()
  },
  mounted() {
      this.getAllUsers()

      //actions menu
      setTimeout(() => {
          this.actionsMenu.push({label:  this.t["80"], icon:'pi pi-fw pi-upload'})
          this.actionsMenu.push({separator:true})
          this.actionsMenu.push({label: this.t["81"], icon:'pi pi-fw pi-file-excel', command: () => {this.exportUsers()}})
      }, 500)
  },
  computed: {

  },
  methods: {
    getAllUsers() {
      this.loading = true
      axios
        .post( 'administration/user/list', {
          'criteria': [
            {'fn':'uid', 'eq':this.UIDFilterOperator, 'fv':this.UIDFilterValue},
            {'fn':'uname', 'eq':this.NameFilterOperator, 'fv':this.NameFilterValue},
            {'fn':'email', 'eq':this.EmailFilterOperator, 'fv':this.EmailFilterValue},
            {'fn':'role', 'eq': this.RoleFilterOperator, 'fv':this.RoleFilterValue},
            {'fn':'status', 'eq':this.StatusFilterOperator, 'fv':this.StatusFilterValue},
            {'fn':'exp', 'eq':'<=', 'fv':''}
          ],
          'orderByField': 'uid',
          'direction': 'ASC'
        }, {
          headers: {
            Authorization: this.token
          }
        })
        .then( response => {
          if(response.status === 200 && response.data.rmsList[0].retCode === 0){
            //write users list to variable
            setTimeout(() => {
              this.allUsers = response.data.retObj

              this.loading = false
            }, 10)

          }else {
            this.$toast.add({severity:'error', summary: 'Error', detail:response.data.rmsList[0].retMsg, life: 3000})
          }
        })
        .catch(error => {
          console.log("There was an error loading users list!")
          console.log(error)
        })
    },
      clearUsersFilter() {
          this.UIDFilterOperator = '='
          this.UIDFilterValue = ''
          this.$refs.userIdFilter.deleteValues()
          this.NameFilterOperator = '='
          this.NameFilterValue = ''
          this.$refs.userNameFilter.deleteValues()
          this.EmailFilterOperator = '='
          this.EmailFilterValue = ''
          this.$refs.emailFilterValue.deleteValues()
          this.StatusFilterOperator =  'NOT IN'
          this.StatusFilterValue = []
          this.$refs.userStatusFilter.deleteValues()
          this.RoleFilterOperator = 'NOT IN'
          this.RoleFilterValue = []
          this.$refs.roleFilter.deleteValues()
          this.getAllUsers()
      },
      //export users
      exportUsers(){
        this.$refs.users.exportCSV()
      },
      //set UID Filter Operator
      getUIDFilterOperator(data) {
        this.UIDFilterOperator = data
      },
      //set UID Filter Value
    getUIDFilterValue(data) {
        this.UIDFilterValue = data
    },
    //set Name Filter Operator
    getNameFilterOperator(data) {
        this.NameFilterOperator = data
    },
      //set Name Filter Value
      getNameFilterValue(data) {
        this.NameFilterValue = data
      },
      //set Email Filter Operator
      getEmailFilterOperator(data) {
          this.EmailFilterOperator = data
      },
      //set Email Filter Value
      getEmailFilterValue(data) {
          this.EmailFilterValue = data
      },
      //set Status Filter Operator
      getStatusFilterOperator(data) {
          this.StatusFilterOperator = data
      },
      //set Status Filter Value
      getStatusFilterValue(data) {
          this.StatusFilterValue = data
      },
      //set Role Filter Operator
      getRoleFilterOperator(data) {
          this.RoleFilterOperator = data
      },
      //set Role Filter Value
      getRoleFilterValue(data) {
          this.RoleFilterValue = data
      },
      initFilter() {
        this.filters = {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      },
      clearFilter() {
        this.initFilter()
      }
  }
}

</script>

<style scoped lang='scss'>

.hr-line-dashed-bottom {
  border-bottom: 1px dashed lightgray;
}

.hr-line-bottom {
  border-bottom: 1px solid lightgray;
}

.status-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.Active {
  background-color: #c8e6c9;
  color: #256029;
}

.Blocked {
  background-color: #ffcdd2;
  color: #c63737;
}

.Expired {
  background-color: #ffd8b2;
  color: #805b36;
}

.Unconfirmed {
  background-color: #b3e5fc;
  color: #23547b;
}
</style>